import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

import { User } from '../models/user';
import { LogService } from './log.service';

@Injectable()
export class UserService {

  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private auth: AngularFireAuth,
    private log: LogService,
    private router: Router,
  ) {
    this.auth.user.subscribe(async (user) => {
      // NOTE user is null if user is not authenticated
      if (user) {
        const { claims } = await user.getIdTokenResult();
        this.user$.next(new User(user.uid, user.email, user.displayName, claims.customerId));
      }
    });
  }

  loginWithBasic(email: string, password: string): Promise<auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  loginWithGoogle(): Promise<auth.UserCredential> {
    return this.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  signup(email: string, password: string): Promise<auth.UserCredential> {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  signOut() {
    this.auth.signOut();
    this.user$.next(null);
    this.router.navigate(['/login']);
  }
}