import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { Session } from '../models';
import { RESULTS_COLLECTION } from '../../../../shared/src/constants';
import { Results } from '../../../../shared/src/models/results';
import { Observable } from 'rxjs';

@Injectable()
export class ResultsService {

  constructor(private afs: AngularFirestore) { }

  getResults(session: Session): Observable<Results> {
    return this.afs.collection(RESULTS_COLLECTION).doc<Results>(session.id).valueChanges();
  }

}