import { Component, OnInit } from '@angular/core';

import { OrderService } from 'src/app/services';
import { OrderItem } from '../../../../../../shared/src/models/order';

@Component({
  selector: 'app-account-table',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountTableComponent implements OnInit {
  items: OrderItem[];

  constructor(
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
    this.orderService.calculateAccount().subscribe(items => this.items = items);
  }

}
