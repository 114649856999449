<div fxLayout="row" fxLayoutAlign="center center">
  <igx-card type="outlined">
    <igx-card-content fxLayout="column" fxLayoutAlign="center stretch">
      <div class="card-content-item" fxLayout="column" fxLayoutAlign="start">
        <h3>Migration Notice</h3>
        <p>
          The <a href="https://app.talevation.com/v3" target="_blank">https://app.talevation.com/v3</a>
          application has been sunset, and all data has been migrated to <a href="https://app.talevation.io"
            target="_blank">https://app.talevation.io</a>.
        </p>
        <p>
          If you are a legacy customer or candidate, please complete the following steps to access the new application.
        </p>
        <ol>
          <li>Visit <a routerLink="/login">https://app.talevation.io/login</a>.</li>
          <li>On the login screen, enter the email address you previously used.</li>
          <li>Click the RESET PASSWORD link at the bottom of the form. This will send you an email allowing you to
            create a password in the new application.</li>
          <li>Login using your email address and newly created password.</li>
        </ol>
        <p>
          <b>Please note that you will not be able to login until you
            reset your password - even if your password has not changed and previously worked. After resetting your
            password and logging in, all previous orders and assessments will display.</b>
        </p>
        <p>
          If you have any questions, please contact <a href="mailto:support@talevation.com">support@talevation.com</a>.
        </p>
      </div>
    </igx-card-content>
  </igx-card>
</div>