<button *ngIf="!mini" id="create-session-button" igxButton="raised" igxButtonColor="white" igxRipple="white"
  (click)="dialog.open()">
  <igx-icon fontSet="material">add_circle</igx-icon>
  &nbsp;Create Assessment
</button>

<igx-icon *ngIf="mini" id="create-session-button" style="padding: 0px; margin: 0px;" fontSet="material"
  (click)="dialog.open()">
  add_circle
</igx-icon>

<igx-dialog id="create-session-dialog" #dialog title="Create Assessment" [leftButtonLabel]="leftButtonLabel"
  [rightButtonLabel]="rightButtonLabel" [closeOnOutsideSelect]="true" (onLeftButtonSelect)="dialog.close()"
  (onRightButtonSelect)="createSession(dialog)">

  <form class="dialog-content-group">
    <div class="dialog-input-group" fxLayout="row" fxLayoutAlign="start start">
      <igx-radio [checked]="!isSelfAssess" (click)="setSelfAssess(false)">Candidate assessment</igx-radio>
      <igx-radio [checked]="isSelfAssess" (click)="setSelfAssess(true)">Self-assessment</igx-radio>
    </div>

    <div class="dialog-input-group">
      <igx-select id="create-session-assessment" name="assessment" [(ngModel)]="selected">
        <label igxLabel>Choose an assessment</label>
        <igx-select-item *ngFor="let item of items" [value]="item" [disabled]="item.quantity <= 0">
          {{ getItemTitle(item) }}
        </igx-select-item>
      </igx-select>
      <div><small class="help-text">Missing assessment? Check your <a [routerLink]="['/account']"
            (click)="dialog.close()">account</a>
          balance.</small></div>
      <!-- <app-chooser></app-chooser> -->
    </div>

    <div class="dialog-input-group" fxLayout="row" fxLayoutAlign="start start">
      <igx-input-group>
        <input id="create-session-firstname" igxInput [(ngModel)]="candidate.firstName" name="firstName" type="text"
          required="required" [disabled]="isSelfAssess" />
        <label igxLabel for="firstName">First name</label>
      </igx-input-group>

      <igx-input-group>
        <input id="create-session-lastname" igxInput [(ngModel)]="candidate.lastName" name="lastName" type="text"
          required="required" [disabled]="isSelfAssess" />
        <label igxLabel for="lastName">Last name</label>
      </igx-input-group>
    </div>

    <div class="dialog-input-group" fxLayout="row" fxLayoutAlign="start center">
      <igx-input-group fxFlex="95">
        <input id="create-session-email" igxInput [(ngModel)]="candidate.email" name="email" type="email"
          required="required" [disabled]="isSelfAssess" [pattern]="regex" />
        <label igxLabel for="email">Email</label>
      </igx-input-group>
      <igx-icon fontSet="material" [isActive]="false" color="#00a5c3" #target="tooltipTarget"
        [igxTooltipTarget]="tooltipRef">help_outline
      </igx-icon>
      <div #tooltipRef="tooltip" igxTooltip>
        Email addresses are used to ensure candidates have access to their assessments after sign up and login.
      </div>
    </div>

    <div class="dialog-input-group" *ngIf="!isSelfAssess">
      <igx-checkbox id="create-session-notify" name="emailManager" [(ngModel)]="emailManager">
        Notify me via email when results are ready.
      </igx-checkbox>
      <igx-checkbox id="create-session-invite" name="inviteCandidate" [(ngModel)]="inviteCandidate">
        Email assessment invitation to candidate.
      </igx-checkbox>
    </div>

    <igx-linear-bar type="default" [value]="0" class="indeterminate"
      [indeterminate]="rightButtonLabel === progressDialogLabel" [textVisibility]="false"></igx-linear-bar>
  </form>

</igx-dialog>