import { Component, Input } from '@angular/core';
import { IgxNavbarComponent, IgxNavigationDrawerComponent } from 'igniteui-angular';
import { UserService } from 'src/app/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  @Input() drawer: IgxNavigationDrawerComponent;

  actionIcon = 'menu';
  actionVisible = false;

  constructor(userService: UserService) {
    userService.user$.subscribe(user => this.actionVisible = user !== null);
  }

  menuClicked(event: IgxNavbarComponent) {
    if (this.drawer) {
      this.drawer.toggle();
    }
  }
}