import { Component, Input, OnInit } from '@angular/core';
import { Vendor } from '../../../../../shared/src/models/assessment';
import { Candidate } from '../../../../../shared/src/models/candidate';
import { NotifyService, OrderService, SessionService, UserService, LogService } from 'src/app/services';
import { IgxDialogComponent } from 'igniteui-angular';
import { OrderItem } from '../../../../../shared/src/models/order';
import { EmailRegEx, isEmailValid } from '../../../../../functions/src/utils/email';
import { Session } from 'src/app/models';

export enum SessionCreationMessages {
  MISSING_ASSESSMENT = 'Please choose an assessment or re-order if there are no more assessments available.',
  EMPTY_QUANTITY = 'Please re-order to create another assessment.',
  MISSING_CANDIDATE = 'Please enter the candidate\'s name and email address.',
  INVALID_EMAIL = 'The candidate\'s email address appears invalid.',
};

enum Options {
  EmailManager = 'EmailManager',
  InviteCandidate = 'InviteCandidate',
}

@Component({
  selector: 'app-create-session',
  templateUrl: './create-session.component.html',
  styleUrls: ['./create-session.component.scss']
})
export class CreateSessionComponent implements OnInit {

  @Input() mini = false;

  closeDialogLabel = 'Close';
  actionDialogLabel = 'Create';
  progressDialogLabel = 'Creating ...';

  leftButtonLabel = this.closeDialogLabel;
  rightButtonLabel = this.actionDialogLabel;

  items: OrderItem[] = [];

  allowCandidate = false;
  emailManager = false;
  inviteCandidate = false;
  isSelfAssess = false;

  candidate: Candidate = {
    firstName: '',
    lastName: '',
    email: ''
  }

  selected: OrderItem;

  lastAccountFetch: number = 0;

  regex = EmailRegEx;

  constructor(
    private log: LogService,
    private orderService: OrderService,
    private notifyService: NotifyService,
    private sessionService: SessionService,
    private userService: UserService,
  ) {

  }

  ngOnInit() {
    const emailManager = localStorage.getItem(Options.EmailManager);
    const inviteCandidate = localStorage.getItem(Options.InviteCandidate);

    this.emailManager = emailManager === 'true';
    this.inviteCandidate = inviteCandidate === 'true';

    // this is the actual balance from firestore
    this.orderService.calculateAccount().subscribe(items => {
      this.lastAccountFetch = Date.now();
      // remove 0 quantities from the list
      this.items = items.filter(item => item.quantity > 0);
    });

    // these is manual accounting within the app
    this.sessionService.deleted$.subscribe(sessions => {
      sessions.forEach(session => {
        if (this.lastAccountFetch < Date.now()) {
          const item = this.items.find(item => item.sku === session.assessmentId);

          if (item) {
            item.quantity = item.quantity + 1;
          }
        }
      });
    });

  }

  reset(delay = 0) {
    setTimeout(() => {
      this.leftButtonLabel = this.closeDialogLabel;
      this.rightButtonLabel = this.actionDialogLabel;

      this.allowCandidate = false;
      this.isSelfAssess = false;

      this.candidate = {
        firstName: '',
        lastName: '',
        email: ''
      }
    }, delay);
  }

  createSession(dialog: IgxDialogComponent): void {
    // guard against invalid data or the user trying to re-create too soon
    if (!this.validateForm() || this.rightButtonLabel === this.progressDialogLabel) {
      return;
    }

    // signal progress to the user
    this.rightButtonLabel = this.progressDialogLabel;

    const { sku, title, vendor } = this.selected;

    // this makes a manual adjustment to prevent server side delays to get the current balance
    // an observable on the account should overwrite
    this.items.forEach(item => {
      if (item.sku === sku) {
        item.quantity = item.quantity - 1;
      }
    });

    this.sessionService.createSession({ id: sku, title, vendor: (vendor as Vendor) }, this.candidate, this.emailManager)
      .subscribe((id: string) => {
        if (this.inviteCandidate) {
          const message = this.notifyService.createInvite(this.candidate.email, this.candidate.firstName, [title], [id]);
          this.notifyService.sendEmail(message).subscribe(() => {
            dialog.close();
            this.reset();
          });
        } else {
          dialog.close();
          this.reset();
        }
      });

    // save the user's preferences for convenience in return visits
    this.saveOptions();
  }

  getItemTitle(item: OrderItem) {
    const { sku, title } = item;
    return (sku.endsWith('EN_US') || sku.indexOf('-') === -1) ? title : `${title} (${sku.substring(sku.lastIndexOf('-') + 1)})`;
  }

  validateForm(): boolean {
    if (!this.selected) {
      this.log.show(SessionCreationMessages.MISSING_ASSESSMENT);
      return false;
    }

    if (this.selected.quantity <= 0) {
      this.log.show(SessionCreationMessages.EMPTY_QUANTITY);
      return false;
    }

    if (!this.candidate.firstName || !this.candidate.lastName || !this.candidate.email) {
      this.log.show(SessionCreationMessages.MISSING_CANDIDATE);
      return false;
    }

    if (!isEmailValid(this.candidate.email)) {
      this.log.show(SessionCreationMessages.INVALID_EMAIL);
      return false;
    }

    return true;
  }

  saveOptions() {
    localStorage.setItem(Options.EmailManager, this.emailManager.toString());
    localStorage.setItem(Options.InviteCandidate, this.inviteCandidate.toString());
  }

  setSelfAssess(selfAssess = false) {
    if (selfAssess) {
      this.isSelfAssess = selfAssess;
      this.userService.user$.subscribe(user => {
        const [firstName, lastName] = user.name.split(' ');
        const { email } = user;

        this.candidate = {
          firstName,
          lastName,
          email
        }
      });
    } else {
      this.reset();
    }
  }
}
