<igx-dialog #dialog [closeOnOutsideSelect]="true" rightButtonLabel="Close" (onRightButtonSelect)="dialog.close()">
  <div *ngIf="details" fxLayout="column" class="dialog-content-group">
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <igx-icon class="assessment-details-icon">help_outline</igx-icon>
      <div *ngIf="details.questions.length === 1" class="assessment-details-stat">{{ details.questions.length }}
        Question
      </div>
      <div *ngIf="details.questions.length > 1" class="assessment-details-stat">{{ details.questions.length }} Questions
      </div>
      <igx-icon class="assessment-details-icon">timer</igx-icon>
      <div *ngIf="details.timeLimit !== 999" class="assessment-details-stat">{{details.timeLimit }}
        Minute Time Limit</div>
      <div *ngIf="details.timeLimit === 999" class="assessment-details-stat">No Time Limit</div>

    </div>
    <div class="assessment-details-description">
      {{ details.description }}
    </div>
  </div>
</igx-dialog>