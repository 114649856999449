import { Component, OnInit } from '@angular/core';

import { SessionsTable } from './sessions-table';
import { SessionStatus } from '../../../../../shared/src/models/session';
import { SessionService, UserService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sessions-completed',
  templateUrl: './completed-sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class CompletedSessionsComponent extends SessionsTable implements OnInit {

  constructor(route: ActivatedRoute, sessionService: SessionService, userService: UserService) {
    super(sessionService, SessionStatus.COMPLETED, userService);

    // NOTE we manually complete the session when the user is sent back to the completed page with an ID
    route.queryParams.subscribe(params => {
      const { id } = params;
      if (id) {
        sessionService.completeSession(id);
      }
    });
  }

  ngOnInit() {
    super.loadSessions();
  }

}

@Component({
  selector: 'app-sessions-expired',
  templateUrl: './expired-sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class ExpiredSessionsComponent extends SessionsTable implements OnInit {

  constructor(sessionService: SessionService, userService: UserService) {
    super(sessionService, SessionStatus.EXPIRED, userService);
  }

  ngOnInit() {
    super.loadSessions();
  }

}

@Component({
  selector: 'app-sessions-inprogress',
  templateUrl: './inprogress-sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class InProgressSessionsComponent extends SessionsTable implements OnInit {

  constructor(sessionService: SessionService, userService: UserService) {
    super(sessionService, SessionStatus.IN_PROGRESS, userService);
  }

  ngOnInit() {
    super.loadSessions();
  }

}

@Component({
  selector: 'app-sessions-notstarted',
  templateUrl: './notstarted-sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class NotStartedSessionsComponent extends SessionsTable implements OnInit {

  constructor(
    sessionService: SessionService,
    userService: UserService,
  ) {
    super(sessionService, SessionStatus.NOT_STARTED, userService);
  }

  ngOnInit() {
    super.loadSessions();

    // NOTE many users are confused on what to do so show the dialog in no sessions exist
    this.sessionService.getSessions(this.status).subscribe(sessions => {
      if (sessions.length === 0 && this.isCustomer) {
        // FIXME this is a bit hacky
        // @ts-ignore
        setTimeout(() => {
          const element: HTMLButtonElement = document.querySelector('#create-session-button');
          if (element) {
            element.click();
          }
        }, 1000);
      }
    });
  }

}

