<igx-grid #grid [data]="sessions" [autoGenerate]="false" [paging]="true" [perPage]="15"
  [rowSelection]="isCustomer ? 'multiple' : 'none'" [cellSelection]="'none'" [showToolbar]="true"
  toolbarTitle="{{status}} Assessments" (onRowSelectionChange)="handleRowSelection($event)" [emptyGridMessage]="isCustomer ? 'Assessments will appear here automatically when they are created.' :
  'Assessments will appear here automatically when they are assigned to you.'" [exportCsv]="true"
  exportCsvText="Export to CSV" [allowFiltering]="filterable" [height]="height">
  <ng-template igxToolbarCustomContent let-grid="grid">
    <app-create-message *ngIf="isCustomer" [sessions]="selected" [grid]="grid"></app-create-message>
    <app-delete-session *ngIf="isCustomer" [sessions]="selected" [grid]="grid"></app-delete-session>
    <button igxButton="outlined" igxRipple (click)="toggleFilter()">
      <igx-icon fontSet="material">filter_list</igx-icon>
    </button>
  </ng-template>
  <igx-column *ngIf="hasUserSession" header=" " [filterable]="false" width="140">
    <ng-template igxCell let-cell="cell">
      <app-session-button [session]="cell.rowData"></app-session-button>
    </ng-template>
  </igx-column>
  <igx-column *ngIf="isCustomer" field="messageStatus" header=" " [filterable]="false" width="68px">
    <ng-template igxCell let-cell="cell">
      <div *ngIf="cell.rowData.messageStatus" [ngSwitch]="cell.rowData.messageStatus.state" #target="tooltipTarget"
        [igxTooltipTarget]="tooltip">
        <igx-icon fontSet="material" color="#757575" *ngSwitchCase="'processed'">email</igx-icon>
        <igx-icon fontSet="material" color="#757575" *ngSwitchCase="'delivered'">mark_email_read</igx-icon>
        <igx-icon fontSet="material" color="#f4511e" *ngSwitchCase="'dropped'">error</igx-icon>
        <igx-icon fontSet="material" color="#ffb300" *ngSwitchCase="'deferred'">update</igx-icon>
        <igx-icon fontSet="material" color="#f4511e" *ngSwitchCase="'blocked'">error</igx-icon>
        <igx-icon fontSet="material" color="#f4511e" *ngSwitchCase="'bounce'">error</igx-icon>
        <igx-icon fontSet="material" color="#66bb6a" *ngSwitchCase="'open'">visibility</igx-icon>
        <igx-icon fontSet="material" color="#66bb6a" *ngSwitchCase="'click'">check_circle</igx-icon>
        <div #tooltip="tooltip" igxTooltip>
          {{ cell.rowData.messageStatus.reason !== '' ? cell.rowData.messageStatus.reason :
          cell.rowData.messageStatus.state | uppercase }}
        </div>
      </div>
    </ng-template>
    <igx-column *ngIf="isCustomer" field="candidateName" [sortable]="true" header="Candidate"></igx-column>
  </igx-column>
  <igx-column field="assessmentTitle" [sortable]="true" header="Assessment">
    <ng-template igxCell let-cell="cell">
      {{cell.rowData.assessmentTitle}}
    </ng-template>
  </igx-column>
  <igx-column field="createDate" [sortable]="true" header="Created On"></igx-column>
</igx-grid>
<app-assessment-overlay #overlay></app-assessment-overlay>