// TODO (van) move away from exporting const string variables
// firestore
export const CATALOG_COLLECTION = 'catalog';
export const CUSTOMER_COLLECTION = 'customers';
export const ORDER_COLLECTION = 'orders';
export const RESULTS_COLLECTION = 'results';
export const SESSION_COLLECTION = 'sessions';
export const TICKET_COLLECTION = 'tickets';

// storage
export const RESULTS_FOLDER = 'results';

// functions
export const FN_COMPLETE_SESSION = 'completeSession';
export const FN_CREATE_SESSION = 'createSession';
export const FN_DELETE_SESSION = 'deleteSession';
export const FN_PROVISION_SESSION = 'provisionSession';
export const FN_SEND_EMAIL = 'sendEmail';

export enum Collection {
  Catalog = 'catalog',
  Customers = 'customers',
  Invitations = 'invitations',
  Orders = 'orders',
  Results = 'results',
  Sessions = 'sessions',
};

export enum Function {
  EmailSend = 'sendEmail',
  SendCandidateInvitation = 'sendCandidateInvitation',
  SessionCreate = 'createSession',
  SessionComplete = 'completeSession',
  SessionDelete = 'deleteSession',
  SessionProvision = 'provisionSession',
  SessionReset = 'resetSession',
};