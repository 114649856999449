<div #target="tooltipTarget" [igxTooltipTarget]="tooltipRef">
  <button igxButton="outlined" igxRipple igxRippleCentered="true" [disabled]="disabled" (click)="dialog.open()">
    <igx-icon fontSet="material">email</igx-icon>
  </button>
</div>

<div #tooltipRef="tooltip" igxTooltip>
  {{ tooltip }}
</div>

<igx-dialog #dialog [title]="'Email Candidate'" [leftButtonLabel]="leftButtonLabel"
  [rightButtonLabel]="rightButtonLabel" (onLeftButtonSelect)="dialog.close()" [closeOnOutsideSelect]="true"
  (onRightButtonSelect)="sendMessage(dialog)">
  <div class="message" fxLayout="column" fxLayoutAlign="start stretch">
    <igx-input-group type="border">
      <igx-prefix>
        <igx-icon>person</igx-icon>
      </igx-prefix>
      <input igxInput name="to" type="text" [(ngModel)]="message.to" disabled />
    </igx-input-group>
    <igx-input-group type="border">
      <igx-prefix>
        <igx-icon>subject</igx-icon>
      </igx-prefix>
      <input igxInput name="to" type="text" [(ngModel)]="message.subject" />
    </igx-input-group>
    <igx-input-group type="border">
      <textarea igxInput id="content" type="text" [value]="message.content" rows="14"></textarea>
    </igx-input-group>
    <igx-linear-bar type="default" [value]="0" class="indeterminate"
      [indeterminate]="rightButtonLabel === progressDialogLabel" [textVisibility]="false"></igx-linear-bar>
  </div>
</igx-dialog>