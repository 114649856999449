import { IRowSelectionEventArgs } from 'igniteui-angular';

import { SessionService, UserService } from 'src/app/services';
import { Session } from 'src/app/models/session';
import { SessionStatus } from '../../../../../shared/src/models/session';

export class SessionsTable {

  height = null;

  hasUserSession = false;
  isCustomer = false;

  filterable = false;

  selected: Session[] = [];
  sessions: Session[] = [];

  constructor(
    protected sessionService: SessionService,
    public status: SessionStatus,
    protected userService: UserService,
  ) {
    this.userService.user$.subscribe(user => this.isCustomer = user ? (user.customerId !== undefined) : false);
  }

  loadSessions(): void {
    this.sessionService.getSessions(this.status).subscribe(sessions => {
      this.sessions = sessions;
      this.hasUserSession = this.sessions.find(session => session.belongsToUser) !== undefined;
    });
  }

  handleRowSelection(event: IRowSelectionEventArgs) {
    this.selected = event.newSelection;
  }

  start(session: Session) {
    this.sessionService.startSession(session);
  }

  toggleFilter() {
    this.filterable = !this.filterable;
  }

}
