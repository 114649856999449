import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MD5 } from 'crypto-js';

import { AssessmentMetadata } from '../../../../shared/src/models/assessment';
import { CATALOG_COLLECTION } from '../../../../shared/src/constants';

@Injectable()
export class CatalogService {

  constructor(private afs: AngularFirestore) { }

  getAssessment(title: string): Observable<AssessmentMetadata> {
    // NOTE the shop renamed "Test" to "Assessment" so convert the title if needed
    if (title.endsWith('Assessment')) {
      title = title.replace('Assessment', 'Test');
    }

    return this.afs.collection(CATALOG_COLLECTION).doc(MD5(title.toLowerCase()).toString()).get().pipe(
      map(snapshot => snapshot.data() as AssessmentMetadata)
    );
  }

}