import { Injectable, NgModule } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Routes, Router, RouterModule, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  AuthComponent,
  CompletedSessionsComponent,
  NotStartedSessionsComponent,
  OrdersComponent,
  InProgressSessionsComponent,
  ExpiredSessionsComponent,
  AccountTableComponent,
  MigrationComponent,
} from './components';

import { SessionStatus } from '../../../shared/src/models/session';

@Injectable({
  providedIn: 'root',
})
class AuthGuard implements CanActivate {

  constructor(private auth: AngularFireAuth,
    private router: Router) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      map(user => {
        if (user) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }),
      catchError((err) => {
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}

const routes: Routes = [
  { path: '', redirectTo: `/sessions/${SessionStatus.NOT_STARTED}`, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'login', component: AuthComponent },
  { path: 'register', component: AuthComponent },
  { path: 'welcome', component: AuthComponent },
  { path: 'migrate', component: MigrationComponent },
  { path: 'account', component: AccountTableComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.COMPLETED}`, component: CompletedSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.COMPLETED}`.toLowerCase(), component: CompletedSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.EXPIRED}`, component: ExpiredSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.EXPIRED}`.toLowerCase(), component: ExpiredSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.IN_PROGRESS}`, component: InProgressSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.IN_PROGRESS}`.toLowerCase(), component: InProgressSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.NOT_STARTED}`, component: NotStartedSessionsComponent, canActivate: [AuthGuard] },
  { path: `sessions/${SessionStatus.NOT_STARTED}`.toLowerCase(), component: NotStartedSessionsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
