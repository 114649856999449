import { Candidate } from './candidate';
import { Assessment } from './assessment';
import { RoleBased } from './role';
import { MessageStatus } from './message';

export enum SessionStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  EXPIRED = 'Expired',
}

export interface SessionOptions {
  notifyOnCompletion?: boolean;
  inviteCandidate?: boolean;
  expires?: number | string;
}

export interface Session extends RoleBased {
  id: string;
  assessment: Assessment;
  candidate: Candidate;
  status: SessionStatus;
  // sendgrid notification details
  messageStatus?: MessageStatus;
  // shopify related details
  customerId: string;
  // vendor related details
  extId?: string;
  extId2?: string;
  url?: string;
  // timestamps
  createTimestamp: number;
  provisionTimestamp?: number;
  startTimestamp?: number;
  endTimestamp?: number;
  resultsTimestamp?: number;
  // optional settings
  notifyOnCompletion?: boolean;
  inviteCandidate?: boolean;
  expires?: number | string;
  // deprecated
  notifyByEmail?: boolean;
  score?: string;
  seatTime?: string;
}
