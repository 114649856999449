<button igxButton="outlined" igxRipple igxRippleCentered="true" [disabled]="!isDeleteable()" (click)="dialog.open()"
  #target="tooltipTarget" [igxTooltipTarget]="tooltip">
  <igx-icon fontSet="material">cancel</igx-icon>
</button>

<div #tooltip="tooltip" igxTooltip>
  Cancel assessment
</div>

<igx-dialog #dialog title="Cancel Assessments" [leftButtonLabel]="leftButtonLabel" [rightButtonLabel]="rightButtonLabel"
  (onLeftButtonSelect)="dialog.close()" (onRightButtonSelect)="cancel(dialog)" (onOpen)="reset()"
  [closeOnOutsideSelect]="true">
  <div class="dialog-content-group">
    <p>
      Are you sure you want to cancel the following assessments? After cancellation you will be able to re-create
      assessments.
    </p>
    <ul>
      <li *ngFor="let session of sessions"><b>{{ session.assessmentTitle }}</b> for
        <b>{{session.candidateName }}</b>
      </li>
    </ul>
    <p>
    </p>
    <igx-linear-bar type="default" [value]="0" class="indeterminate" [textVisibility]="false"
      [indeterminate]="this.rightButtonLabel === this.progressDialogLabel">
    </igx-linear-bar>
  </div>
</igx-dialog>