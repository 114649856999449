import { Injectable } from '@angular/core';
import { IgxToastComponent } from 'igniteui-angular';

import * as FullStory from '@fullstory/browser';

@Injectable()
export class LogService {

  toast: IgxToastComponent;

  constructor() {

  }

  setToastComponent(toast: IgxToastComponent) {
    this.toast = toast;
  }

  show(message: string, error?: Error) {
    this.toast.message = message;
    this.toast.show();

    FullStory.event('Toast Message', {
      message_str: message,
      isError_bool: error !== undefined && error !== null
    });

    if (error) {
      console.error(error.message);
      console.error(error.stack);
    }
  }

}