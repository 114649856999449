<igx-nav-drawer *ngIf="user" #drawer id="navigation" [pinThreshold]="0" [pin]="true" [isOpen]="false">
  <ng-template igxDrawer>
    <nav>
      <span *ngIf="user.customerId">
        <app-create-session></app-create-session>
      </span>
      <span *ngIf="assessmentNav.length > 0" igxDrawerItem [isHeader]="true">Assessments</span>
      <span *ngFor="let item of assessmentNav" [routerLink]="[item.path, item.title]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active" (click)="resetBadge(item)">
        <igx-icon>{{ item.icon }}</igx-icon>
        <span>{{ item.title }}</span>
        <igx-badge *ngIf="item.curr !== undefined && (item.curr - item.prev) > 0" [value]="item.curr - item.prev"
          class="badge-style"></igx-badge>
      </span>

      <span *ngIf="manageNav.length > 0" igxDrawerItem [isHeader]="true">Manage</span>
      <span *ngFor="let item of manageNav" [routerLink]="[item.path]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active" (click)="resetBadge(item)">
        <igx-icon>{{ item.icon }}</igx-icon>
        <span>{{ item.title }}</span>
        <igx-badge *ngIf="item.curr !== undefined && (item.curr - item.prev) > 0" [value]="item.curr - item.prev"
          class="badge-style"></igx-badge>
      </span>

      <span *ngIf="helpNav.length > 0" igxDrawerItem [isHeader]="true">Help</span>
      <span *ngFor="let item of helpNav" [routerLink]="[item.path]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active">
        <igx-icon>{{ item.icon }}</igx-icon>
        <span>{{ item.title }}</span>
      </span>
    </nav>
  </ng-template>
  <ng-template igxDrawerMini>
    <nav>
      <span *ngIf="user.customerId" igxDrawerItem igxDrawerItem igxRipple>
        <app-create-session [mini]="true"></app-create-session>
      </span>
      <igx-divider [middle]="true" inset="16px"></igx-divider>
      <span *ngFor="let item of assessmentNav" [routerLink]="[item.path, item.title]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active" (click)="resetBadge(item)">
        <igx-icon [ngClass]="{'tap-badge' : item.curr !== undefined && (item.curr - item.prev) > 0}">{{ item.icon }}
        </igx-icon>
      </span>
      <igx-divider [middle]="true" inset="16px"></igx-divider>
      <span *ngFor="let item of manageNav" [routerLink]="[item.path]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active">
        <igx-icon>{{ item.icon }}</igx-icon>
      </span>
      <igx-divider [middle]="true" inset="16px"></igx-divider>
      <span *ngFor="let item of helpNav" [routerLink]="[item.path]" igxDrawerItem igxRipple
        routerLinkActive="igx-nav-drawer__item--active">
        <igx-icon>{{ item.icon }}</igx-icon>
      </span>
    </nav>
  </ng-template>
</igx-nav-drawer>