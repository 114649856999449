import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IgxIconService, IgxToastComponent } from 'igniteui-angular';
import { SessionStatus } from '../../../../../shared/src/models/session';
import { UserService } from 'src/app/services';

type AuthMode = 'login' | 'register' | 'welcome'; // matches the route names

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  passwordVerify = new FormControl('', [Validators.required]);

  authForm: FormGroup = this.form.group({
    email: this.email,
    password: this.password,
    passwordVerify: this.passwordVerify,
  });

  mode: AuthMode = 'login';

  welcomeEmail = '';

  @ViewChild('toast') toast: IgxToastComponent;

  constructor(
    private iconService: IgxIconService,
    private route: ActivatedRoute,
    private form: FormBuilder,
    private router: Router,
    private userService: UserService,
  ) {
    this.iconService.addSvgIcon('google', '/assets/btn_google_dark_normal_ios.svg', 'login');
    this.route.url.subscribe(url => {
      this.mode = url[0].path as AuthMode;

      if (this.mode === 'welcome') {
        this.route.queryParams.subscribe(params => {
          this.welcomeEmail = params.email;
        });
      }
    });
  }

  ngOnInit() {
    this.userService.user$.subscribe(user => {
      if (user) this.router.navigate(['/sessions', SessionStatus.NOT_STARTED]);
    });
  }

  showError(error: Error) {
    // @ts-ignore
    let { code, message } = error;

    switch (code) {
      case 'auth/email-already-in-use':
        message = `${message} If you are an existing user, please reset your password.`;
        break;
      default:
      // use the message as-is
    }

    this.toast.message = message;
    this.toast.open();
  }

  async loginWithBasic() {
    try {
      await this.userService.loginWithBasic(this.email.value, this.password.value);
    } catch (err) {
      this.showError(err);
    }
  }

  async loginWithGoogle() {
    await this.userService.loginWithGoogle();
  }

  async resetPassword(email: string) {
    if (!email) {
      this.toast.message = `Please enter your email address to reset password.`;
      this.toast.open();
    } else {
      try {
        await this.userService.sendPasswordResetEmail(email);

        this.toast.message = `An email has been sent to ${email}.`;
        this.toast.open();
      } catch (err) {
        this.showError(err);
      }
    }
  }

  showMode(mode: AuthMode) {
    this.mode = mode;
  }

  async signup() {
    try {
      await this.userService.signup(this.email.value, this.password.value);
    } catch (err) {
      this.showError(err);
    }
  }

  onSubmit() {

  }
}
