import { Component, Input } from '@angular/core';
import { SessionService } from 'src/app/services';
import { Session } from 'src/app/models/session';

@Component({
  selector: 'app-session-button',
  templateUrl: './session-button.component.html',
  styleUrls: ['./session-button.component.scss']
})
export class SessionButtonComponent {

  @Input() session: Session;

  showProgress = false;

  constructor(private sessionService: SessionService) { }

  startSession() {
    this.showProgress = true;

    this.sessionService.startSession(this.session).subscribe(session => {
      window.location.href = session.url; // redirect rather than open a new tab because of popup blockers
      this.showProgress = false;
    });
  }

}
