<igx-grid #grid [data]="sessions" [autoGenerate]="false" [paging]="true" [perPage]="15" [rowSelection]='"multiple"'
  [cellSelection]="'none'" [showToolbar]="true" toolbarTitle="{{status}} Expired"
  (onRowSelectionChange)="handleRowSelection($event)" [emptyGridMessage]="'There are no expired assessments.'"
  [exportCsv]="true" exportCsvText="Export to CSV" [allowFiltering]="filterable" [height]="height">
  <ng-template igxToolbarCustomContent let-grid="grid">
    <app-delete-session *ngIf="isCustomer" [sessions]="selected" [grid]="grid"></app-delete-session>
    <button igxButton="outlined" igxRipple (click)="toggleFilter()">
      <igx-icon fontSet="material">filter_list</igx-icon>
    </button>
  </ng-template>
  <igx-column *ngIf="isCustomer" field="candidateName" [sortable]="true" header="Candidate"></igx-column>
  <igx-column field="assessmentTitle" [sortable]="true" header="Assessment"></igx-column>
  <igx-column field="createDate" [sortable]="true" header="Created On"></igx-column>
</igx-grid>