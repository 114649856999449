import { Component, Input } from '@angular/core';

/**
 * ButtonAction executes a function using the data passed into the button.
 */
export type ButtonAction = (data: any[]) => void;

/**
 * ButtonValidator checks if the supplied data is valid and if not, the button is disabled.
 * If the button is disabled, a string should be returned to provide feedback to the user or null should
 * be returned to indicate validation is successful.
 */
export type ButtonValidator = (data: any[]) => string | null;

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() action: ButtonAction;        // the function to run on button click
  @Input() icon: string;                // the icon of the button
  @Input() id: string;                  // an HTML element ID assigned to the button
  @Input() link: string;                // a HREF link if the button links to a webpage
  @Input() data: any[] = [];            // the data supplied to the button
  @Input() singleSelection = true;      // whether the button allows single or multiple data inputs  
  @Input() tooltip = '';                // the default tooltip to show
  @Input() validator: ButtonValidator;  // a custom validator if the data needs to be checked to disable the button

  currentTooltip = this.tooltip;  // the current tooltip (usually an error related feedback message)

  /**
   * Checks if the button should be disabled - either by selection count or custom validator function.
   */
  isDisabled(): boolean {
    // if the data is simply empty, the button is always disabled and shows the default tooltip
    if (this.data.length === 0) {
      this.currentTooltip = this.tooltip;
      return true;
    }

    // next check the counts are valid
    if (this.singleSelection) {
      if (this.data.length !== 1) {
        this.currentTooltip = 'Select only one item from the table.';
      }
    } else {
      if (this.data.length !== 0) {
        this.currentTooltip = 'Select more than one item from the table.';
      }
    }

    // finally check the validator if it exists
    if (this.validator) {
      const validation = this.validator(this.data);
      this.currentTooltip = validation === null ? this.tooltip : validation;
    }

    // if at any point the validation string (i.e. an error message) does not match the default tooltip, disable
    return this.tooltip !== this.currentTooltip;
  }

  /**
   * Executes a custom, registered function defined by the `action` input.
   */
  runAction() {
    if (this.action) {
      this.action(this.data);
    } else {
      console.warn(`Button ${this.id} clicked but no action is registered`);
    }
  }

}
