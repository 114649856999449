import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Order, OrderItem } from '../../../../../shared/src/models/order';
import { OrderService, UserService } from 'src/app/services';
import { IRowSelectionEventArgs } from 'igniteui-angular';

class TreeOrder {

  title = '';
  assessments: TreeOrder[] = [];
  email: string;
  date?: string;
  items?: OrderItem[];
  multipleSkus = false;
  number?: number;
  quantity: number;
  url?: string;
  variantId?: string;

  constructor(order: Order, email = '') {
    const date = new Date(order.created).toDateString();
    const { url } = order;

    this.date = date;
    this.email = email;
    this.items = order.items;
    this.number = order.number;
    this.quantity = order.items.reduce((total, item) => total += item.quantity, 0);
    this.url = url;

    const firstItem = order.items[0]; // there should always be one
    if (firstItem) {
      this.multipleSkus = order.items.every(item => item.sku === firstItem.sku);

      this.assessments = order.items.map(item => {
        const { title, quantity, variantId } = item;
        return {
          assessments: [],
          title,
          quantity,
          date,
          url,
          variantId,
        } as TreeOrder;
      });
    } else {
      console.error(`No assessments were found in order ${order.id}`);
    }
  }
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders: TreeOrder[];
  selected: TreeOrder[] = [];

  constructor(
    private orderService: OrderService,
    private userService: UserService,
  ) {

  }

  ngOnInit(): void {
    // NOTE the email address is needed when creating a re-order so it must be inserted into the order
    // this is because the reorder function is not run within OrdersComponent's this context
    this.userService.user$.subscribe(user => {
      if (user) {
        this.orderService.orders$.subscribe(orders => {
          this.orders = orders.map(order => new TreeOrder(order, user.email));
        });
      }
    });
  }

  handleRowSelection(event: IRowSelectionEventArgs) {
    this.selected = event.newSelection;
  }

  /**
   * Shows a receipt by redirecting the user to Shopify for the given order.
   * @param orders any orders used to create the cart (only a single order is expected in the list)
   */
  receiptAction(orders: TreeOrder[]) {
    if (orders.length === 1) {
      window.location.href = orders[0].url;
    } else {
      console.warn(`Expected only a single order but found ${orders.length}`);
    }
  }

  /**
   * Validates that an order has a receipt URL.
   * @param orders any orders used to create the cart (only a single order is expected in the list)
   */
  receiptValidator(orders: TreeOrder): string | null {
    if (orders) {
      const order: TreeOrder = orders[0];

      // if users selects an assessment in the tree, disable the button
      if (order.assessments.length === 0) {
        return 'Select an order to view a receipt';
      }

      if (order.url) {
        return null;
      } else {
        return 'Please contact support for a receipt'
      }
    }
  }

  /**
   * Creates a reorder by redirecting the user to Shopify with a pre-created cart. 
   * @param orders any orders used to create the cart (only a single order is expected in the list)
   */
  reorderAction(orders: TreeOrder[]) {
    if (orders.length === 1) {
      const items = orders[0].items.reduce((url: string, item: OrderItem) => url += `${item.variantId}:${item.quantity},`, '');
      window.location.href = `https://${environment.shopUrl}/cart/${items.substring(0, items.length - 1)}?checkout[email]=${orders[0].email}`;
    } else {
      console.warn(`Expected only a single order but found ${orders.length}`);
    }
  }

  /**
   * Validates that an order can be re-ordered. (Legacy orders do not have the variantId needed to create a re-order.)
   * @param orders any orders used to create the cart (only a single order is expected in the list)
   */
  reorderValidator(orders: TreeOrder): string | null {
    if (orders) {
      const order: TreeOrder = orders[0];

      // if users selects an assessment in the tree, disable the button
      if (order.assessments.length === 0) {
        return 'Select an order to be re-ordered';
      }

      if (order && order.assessments.every(assessment => assessment.variantId)) {
        return null;
      } else {
        return 'Order is not eligible to be re-ordered';
      }
    }
  }

}
