import { Component, OnInit, ViewChild } from '@angular/core';
import { IgxDialogComponent } from 'igniteui-angular';

import { CatalogService } from 'src/app/services';
import { AssessmentMetadata } from '../../../../../../shared/src/models/assessment';

@Component({
  selector: 'app-assessment-overlay',
  templateUrl: './assessment-overlay.component.html',
  styleUrls: ['./assessment-overlay.component.scss']
})
export class AssessmentOverlayComponent implements OnInit {

  @ViewChild('dialog') dialog: IgxDialogComponent;

  details: AssessmentMetadata;

  constructor(
    private catalogService: CatalogService,
  ) { }

  ngOnInit(): void {
  }

  show(title: string) {
    this.catalogService.getAssessment(title).subscribe(details => {
      this.details = details;
      this.dialog.title = details.title;
      this.dialog.open();
    });
  }

}
