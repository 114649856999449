import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IgxDialogComponent, IgxGridComponent } from 'igniteui-angular';

import { Session } from 'src/app/models/session';
import { Message } from '../../../../../../shared/src/models/message';
import { UserService } from 'src/app/services';
import { User } from 'src/app/models';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss']
})
export class CreateMessageComponent implements OnChanges {

  @Input() grid: IgxGridComponent;
  @Input() sessions: Session[];

  closeDialogLabel = 'Close';
  actionDialogLabel = 'Send';
  progressDialogLabel = 'Sending ...';

  leftButtonLabel = this.closeDialogLabel;
  rightButtonLabel = this.actionDialogLabel;

  disabled = true;
  message: Message = { to: '', from: '', subject: '', content: '' };

  defaultTooltip = 'Email assessments to candidate';
  tooltip = this.defaultTooltip;

  user: User;

  constructor(
    private notifyService: NotifyService,
    userService: UserService,
  ) {
    userService.user$.subscribe(user => this.user = user);
    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sessions && this.sessions.length > 0) {
      if (!this.sessions.every(session => session.candidateEmail === this.sessions[0].candidateEmail)) {
        this.disabled = true;
        return;
      } else {
        this.disabled = false;
      }

      const [session] = this.sessions;
      const { candidateName } = session;
      this.message = this.notifyService.createInvite(this.sessions[0].candidateEmail, candidateName,
        this.sessions.map(session => session.assessmentTitle), this.sessions.map(session => session.id));
    } else {
      this.disabled = true;
    }
  }

  reset(delay = 0) {
    if (this.grid) {
      this.grid.selectionService.clearAllSelectedRows();
    }

    setTimeout(() => {
      this.rightButtonLabel = this.actionDialogLabel;

      this.message = {
        to: '',
        from: 'noreply@talevation.com',
        subject: '',
        content: ''
      };
    }, delay);
  }

  sendMessage(dialog: IgxDialogComponent): void {
    // signal progress to the user
    this.rightButtonLabel = this.progressDialogLabel;

    this.notifyService.sendEmail(this.message).subscribe(() => {
      dialog.close();
      this.reset(2000);
    });
  }
}
