import { Component, ViewChild, AfterViewInit } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { IgxToastComponent } from 'igniteui-angular';
import { LogService, UserService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild('toast') toast: IgxToastComponent;

  title = 'Talent Assessments Platform';

  constructor(
    private log: LogService,
    userService: UserService,
  ) {
    userService.user$.subscribe(user => {
      if (user !== null) {
        if (user.customerId) {
          // NOTE to limit session usage, create a FS session only for customers
          FullStory.init({ orgId: 'KRDJ3' });
          FullStory.identify(user.customerId, {
            email: user.email,
            customerId: user.customerId || 'none',
            googleId: user.id,

          });
        }

      }
    })
  }

  ngAfterViewInit(): void {
    this.log.setToastComponent(this.toast);
  }
}
