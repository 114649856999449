import { ErrorHandler, Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';

import { environment } from '../environments/environment';
import { LogService } from './services';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(private log: LogService) {

  }

  async handleError(error) {
    if (error) {
      const message = error.message || 'A general error occurred. Please open a support ticket if you are unable to complete a task.'

      const ignoreList = [
        'Missing or insufficient permissions', // this occurs on the login screen when the user logs out; appears to be a vendor issue
        'Cannot read property \'settings\' of undefined',  // igx throws this; probably trying to read settings to restore table state
        'popup has been closed by the user',  // login error that is sometimes show but a non-issue
      ];

      // if an ignorable message is encountered, warn but don't show the user by returning
      for (const ignorable of ignoreList) {
        if (message.indexOf(ignorable) > -1) {
          console.warn(message);
          return;
        }
      }

      this.log.show(message, error);

      if (environment.production) {
        // send the error to fullstory
        if (window['FS']) {
          try {
            FullStory.event('Client Error', {
              name_str: error.name,
              message_str: error.message
            });
          } catch (e) { }
        }
      }
    }
  }


}