import * as crypto from 'crypto-js';

export class User {

  emailHash: string;
  isCustomer = false;

  constructor(public id: string, public email: string, public name: string, public customerId?: string) {
    this.emailHash = crypto.SHA256(email.toLowerCase()).toString().toUpperCase();
    this.isCustomer = customerId !== undefined;
  }
}