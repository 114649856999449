import { NgModule, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';

import {
  IgxAvatarModule,
  IgxBadgeModule,
  IgxButtonModule,
  IgxCardModule,
  IgxCheckboxModule,
  IgxCsvExporterService,
  IgxExcelExporterService,
  IgxDialogModule,
  IgxDividerModule,
  IgxDropDownModule,
  IgxGridModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxNavbarModule,
  IgxNavigationDrawerModule,
  IgxProgressBarModule,
  IgxRadioModule,
  IgxSelectModule,
  IgxToastModule,
  IgxTreeGridModule,
  IgxTooltipModule,
} from 'igniteui-angular';

import { environment } from '../environments/environment';

import { AppErrorHandler } from './app-error.handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AccountTableComponent,
  AuthComponent,
  AuthStatusComponent,
  CompletedSessionsComponent,
  CreateMessageComponent,
  CreateSessionComponent,
  DeleteSessionComponent,
  DownloadReportComponent,
  ExpiredSessionsComponent,
  InProgressSessionsComponent,
  NavbarComponent,
  OrdersComponent,
  NavComponent,
  NotStartedSessionsComponent,
  SessionButtonComponent,
} from './components';

import { CatalogService, LogService, OrderService, SessionService, ResultsService, UserService } from './services';
import { ButtonComponent } from './components/buttons/button/button.component';
import { MigrationComponent } from './components/migration/migration.component';
import { AssessmentOverlayComponent } from './components/catalog/assessment-overlay/assessment-overlay.component';

@NgModule({
  declarations: [
    AccountTableComponent,
    AppComponent,
    ButtonComponent,
    CompletedSessionsComponent,
    CreateMessageComponent,
    DownloadReportComponent,
    ExpiredSessionsComponent,
    InProgressSessionsComponent,
    NavbarComponent,
    AuthComponent,
    OrdersComponent,
    NotStartedSessionsComponent,
    AuthStatusComponent,
    NavComponent,
    SessionButtonComponent,
    CreateSessionComponent,
    DeleteSessionComponent,
    ButtonComponent,
    MigrationComponent,
    AssessmentOverlayComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    IgxAvatarModule,
    IgxBadgeModule,
    IgxButtonModule,
    IgxCardModule,
    IgxCheckboxModule,
    IgxDialogModule,
    IgxDividerModule,
    IgxDropDownModule,
    IgxGridModule,
    IgxIconModule,
    IgxInputGroupModule,
    IgxNavbarModule,
    IgxNavigationDrawerModule,
    IgxProgressBarModule,
    IgxRadioModule,
    IgxSelectModule,
    IgxToastModule,
    IgxTreeGridModule,
    IgxTooltipModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    CatalogService,
    UserTrackingService,
    IgxCsvExporterService,
    IgxExcelExporterService,
    LogService,
    OrderService,
    SessionService,
    ResultsService,
    UserService,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {
}
