<igx-grid [data]="sessions" [autoGenerate]="false" [paging]="true" [perPage]="15" [cellSelection]="'none'"
  [showToolbar]="true" toolbarTitle="{{status}} Assessments" (onRowSelectionChange)="handleRowSelection($event)"
  [emptyGridMessage]="'Assessments will appear here automatically when they are completed and scored.'"
  [exportCsv]="true" exportCsvText="Export to CSV" [allowFiltering]="filterable" [height]="height">
  <ng-template igxToolbarCustomContent let-grid="grid">
    <button igxButton="outlined" igxRipple (click)="toggleFilter()">
      <igx-icon fontSet="material">filter_list</igx-icon>
    </button>
  </ng-template>
  <igx-column *ngIf="isCustomer" field="id" header=" " [filterable]="false" width="160px">
    <ng-template igxCell let-cell="cell">
      <download-report [session]="cell.rowData"></download-report>
    </ng-template>
  </igx-column>
  <igx-column *ngIf="isCustomer" field="candidateName" [sortable]="true" header="Candidate">
  </igx-column>
  <igx-column field="assessmentTitle" [sortable]="true" header="Assessment">
    <ng-template igxCell let-cell="cell">
      {{cell.rowData.assessmentTitle}}
    </ng-template>
  </igx-column>
  <igx-column field="score" [sortable]="true" header="Score">
    <ng-template igxCell let-cell="cell">
      <span *ngIf="cell.rowData.score">{{cell.rowData.score}}</span>
      <div *ngIf="!cell.rowData.score" class="progress">
        <igx-circular-bar [indeterminate]="true"></igx-circular-bar>
      </div>
    </ng-template>
  </igx-column>
  <!-- remove after migration -->
  <!-- <igx-column field="seatTime" [sortable]="true" header="Seat Time"></igx-column> -->
  <igx-column field="endDate" [sortable]="true" header="Completed On"></igx-column>
</igx-grid>
<app-assessment-overlay #overlay></app-assessment-overlay>