<div fxLayout="row" fxLayoutAlign="center center">
  <igx-card type="outlined">
    <igx-card-content *ngIf="mode != 'welcome'" fxLayout="column" fxLayoutAlign="center stretch">
      <div class="card-content-item" fxLayout="column" fxLayoutAlign="start stretch">
        <div class="social-logins" fxLayout="row" fxLayoutAlign="center start">
          <igx-icon class="google-logo" name="google" fontSet="login"></igx-icon>
          <button class="google-button" igxButton="flat" igxRipple igxRippleCentered="true" igxButtonColor="white"
            igxButtonBackground="#4285f4" (click)="loginWithGoogle()">Sign in with Google</button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <small>or</small>
        </div>
        <form [formGroup]="authForm" class="login-form" (ngSubmit)="onSubmit()">
          <igx-input-group>
            <igx-prefix>
              <igx-icon>person</igx-icon>
            </igx-prefix>
            <label igxLabel for="email">Email</label>
            <input [formControl]="email" igxInput id="email" type="email" required="required" />
          </igx-input-group>
          <igx-input-group>
            <igx-prefix>
              <igx-icon>lock</igx-icon>
            </igx-prefix>
            <label igxLabel>Password</label>
            <input [formControl]="password" igxInput id="password" type="password" required="required" />
          </igx-input-group>
          <igx-input-group *ngIf="mode == 'register'">
            <igx-prefix>
              <igx-icon>lock</igx-icon>
            </igx-prefix>
            <label igxLabel>Verify Password</label>
            <input [formControl]="passwordVerify" igxInput id="password-verify" type="password" required="required" />
            <igx-hint *ngIf="password.value != passwordVerify.value" position="start">Passwords do not match!
            </igx-hint>
          </igx-input-group>
        </form>
        <button *ngIf="mode == 'login'" igxButton="outlined" igxButtonBackground="#FFF" igxRipple="#00a5c3"
          (click)="loginWithBasic()">Login</button>
        <button *ngIf="mode == 'register'" igxButton="outlined" igxButtonBackground="#FFF" igxRipple="#00a5c3"
          (click)="signup()" [disabled]="password.value != passwordVerify.value">Register</button>
      </div>
      <igx-divider [vertical]="true"></igx-divider>
      <div class="card-content-item" fxLayout="column" fxLayoutAlign="center center">
        <span *ngIf="mode === 'login'">First time visiting? Go ahead and <a routerLink="/register">sign up</a> to take
          an assessment.
        </span>
        <span *ngIf="mode === 'register'">Already have an account? Login <a routerLink="/login">here</a> to create
          or take an assessment.</span>
      </div>
      <div id="password-reset" fxLayout="row" fxLayoutAlign="center center">
        <div><small><a href="javascript:void()" (click)="resetPassword(email.value)">RESET PASSWORD</a></small></div>
      </div>
    </igx-card-content>
    <igx-card-content *ngIf="mode == 'welcome'" fxLayout="column" fxLayoutAlign="center stretch">
      <div class="card-content-item" fxLayout="column" fxLayoutAlign="start stretch">
        <h3>Welcome!</h3>
        <p>
          Since this is your first order, let's get your account set up.
        </p>
        <ol>
          <li>Click the <b>Create Account</b> button to create your account.</li>
          <li>Check your <b>{{ welcomeEmail }}</b> inbox for a welcome email.</li>
          <li>Follow the email link, and create a new password.</li>
          <li>Visit <a routerLink="/login">https://app.talevation.io/login</a>.</li>
          <li>Login using your email address and new password.</li>
        </ol>
        <button igxButton="outlined" igxButtonBackground="#FFF" igxRipple="#00a5c3"
          (click)="resetPassword(welcomeEmail)">Create
          Account</button>
      </div>
    </igx-card-content>
  </igx-card>
</div>
<igx-toast #toast message=""></igx-toast>