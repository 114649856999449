import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { CUSTOMER_COLLECTION, ORDER_COLLECTION } from '../../../../shared/src/constants';
import { Customer } from '../../../../shared/src/models/customer';
import { Order, OrderItem } from '../../../../shared/src/models/order';
import { UserService } from './user.service';

@Injectable()
export class OrderService {

  account$: Observable<{ [key: string]: number }> = of({});
  orders$: Observable<Order[]> = of([]);
  items$: Observable<OrderItem[]> = of([]);

  constructor(
    private afs: AngularFirestore,
    userService: UserService,
  ) {
    // creates observables when the identity of the user is known
    // NOTE components should anticipate that any observables are not initialized until the user is logged in
    userService.user$.subscribe(user => {
      if (user) {
        // the logged in user may be a candidate, which has not token claim of an customerId
        if (user.customerId) {
          this.orders$ = this.afs.collection(CUSTOMER_COLLECTION).doc(user.customerId).collection<Order>(ORDER_COLLECTION,
            ref => ref.where("email", "==", user.emailHash).orderBy('number', 'desc')).valueChanges().pipe(
              map((orders: Order[]) => orders.map(order => {
                order.number += 1000; // manually add 1000 to the order because this is where Shopify starts order numbering
                return order;
              })),
            )

          this.items$ = this.mapItems();


          this.account$ = this.afs.collection(CUSTOMER_COLLECTION).doc(user.customerId).valueChanges().pipe(
            map((customer: Customer) => customer.account)
          );
        }
      }
    });
  }

  calculateAccount(): Observable<OrderItem[]> {
    // fetch the account and ordered items (to provide a title for the assessment )
    return combineLatest(
      this.items$,
      this.account$
    ).pipe(
      map(value => {
        const [items, account] = value;

        // for each item ordered, update the quantity with the account entry
        items.forEach(item => item.quantity = item.quantity = account[item.sku]);

        // remove the "Assessment" trailing value if it exists
        return items.map(item => {
          if (item.title.endsWith('Assessment')) {
            item.title = item.title.substring(0, item.title.indexOf('Assessment') - 1);
          }

          return item;
        });
      })
    );
  }

  private mapItems(): Observable<OrderItem[]> {
    return this.orders$.pipe(
      // return an list of OrderItem[]
      map((orders: Order[]) => orders.map(order => order.items)),
      // reduce list of lists
      map((list: OrderItem[][]) => list.reduce((accumulator: OrderItem[], items: OrderItem[]) => [...accumulator, ...items], [])),
      // create a dictionary and tally totals
      map((list: OrderItem[]) => list.reduce((credits: { [key: string]: OrderItem }, item: OrderItem) => {
        const { sku, quantity } = item;

        if (credits[sku]) {
          credits[sku].quantity += quantity;
        } else {
          credits[sku] = item;
        }

        return credits;
      }, {})),
      // return list of totaled OrderItem
      map((credits: { [key: string]: OrderItem }) => Object.values(credits))
    );
  }
}