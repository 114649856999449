import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import 'firebase/firestore';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SESSION_COLLECTION, FN_PROVISION_SESSION, FN_DELETE_SESSION, FN_CREATE_SESSION, FN_COMPLETE_SESSION } from '../../../../shared/src/constants';
import { Assessment } from '../../../../shared/src/models/assessment';
import { Candidate } from '../../../../shared/src/models/candidate';
import { Session as StoredSession } from '../../../../shared/src/models/session';
import { SessionStatus } from '../../../../shared/src/models/session';

import { UserService } from './user.service';
import { Session, User } from '../models';

@Injectable()
export class SessionService {

  // NOTE this is a broadcast expected deletions that could fail server-side
  deleted$ = new Subject<Session[]>();

  completed$: Observable<Session[]>;
  expired$: Observable<Session[]>;
  inProgress$: Observable<Session[]>;
  notStarted$: Observable<Session[]>;

  sessions$: Observable<Session[]>;

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    userService: UserService,
  ) {
    // creates observables when the identity of the user is known
    // NOTE consumers should anticipate that the user is null and the reference to the observable is reassigned
    userService.user$.subscribe(user => {
      if (user) {
        this.completed$ = this.valueChanges(user, SessionStatus.COMPLETED, 'endTimestamp');
        this.expired$ = this.valueChanges(user, SessionStatus.EXPIRED, 'createTimestamp');
        this.inProgress$ = this.valueChanges(user, SessionStatus.IN_PROGRESS, 'provisionTimestamp');
        this.notStarted$ = this.valueChanges(user, SessionStatus.NOT_STARTED, 'createTimestamp');
        this.sessions$ = this.valueChanges(user);
      }
    });
  }

  /**
   * Creates an observable that returns the a list of sessions where the logged in user is a viewer.
   * @param user the logged in User
   * @param status the desired Session's status
   */
  private valueChanges(user: User, status?: SessionStatus, orderBy = 'createTimestamp'): Observable<Session[]> {
    return this.afs.collection<StoredSession>(SESSION_COLLECTION, ref => {
      const query = ref.where("viewers", "array-contains", user.emailHash).orderBy(orderBy, 'desc');
      return status ? query.where('status', '==', status) : query;
    }).valueChanges()
      .pipe(map((sessions: StoredSession[]) => sessions.map(session => new Session(session, user))));
  }

  /**
   * Creates a session placeholder in Firestore and returns the ID.
   * @param assessment the assessment type to create
   * @param candidate the assessment candidate
   * @param notifyByEmail true to email managers upon assessment completion
   */
  createSession(assessment: Assessment, candidate: Candidate, notifyByEmail = false): Observable<string> {
    const callable = this.fns.httpsCallable(FN_CREATE_SESSION);
    return callable({ assessment, candidate, notifyByEmail });
  }

  /**
   * Deletes a session from Firestore.
   * @param sessions the list of Sessions to delete
   */
  deleteSession(sessions: Session[]): Observable<void> {
    this.deleted$.next(sessions);

    const callable = this.fns.httpsCallable(FN_DELETE_SESSION);
    return callable(sessions);
  }

  /**
   * Gets an observable for the given sessions by status. By default, Not Started sessions are returned.
   * @param status the sessions' status
   */
  getSessions(status: SessionStatus = SessionStatus.NOT_STARTED): Observable<Session[]> {
    switch (status) {
      case SessionStatus.COMPLETED: return this.completed$;
      case SessionStatus.EXPIRED: return this.expired$;
      case SessionStatus.IN_PROGRESS: return this.inProgress$;
      case SessionStatus.NOT_STARTED:
      default:
        return this.notStarted$;
    }
  }

  /**
   * Starts a session by provisioning the assessment with the vendor. If the session is already started, the original
   * session will be returned.
   * @param session the session to start and update
   */
  startSession(session: Session): Observable<Session> {
    if (!session.url) {
      const callable = this.fns.httpsCallable(FN_PROVISION_SESSION);
      return callable(session.stored);  // NOTE functions expect the raw "stored" session
    } else {
      return of(session);
    }
  }

  completeSession(id: String) {
    const callable = this.fns.httpsCallable(FN_COMPLETE_SESSION);
    return callable({ id }).subscribe(() => { });
  }
}