<igx-tree-grid [data]="orders" childDataKey="assessments" [autoGenerate]="false" [rowSelection]='"single"'
  [cellSelection]="'none'" [paging]="true" [showToolbar]="true" toolbarTitle="Orders" expansionDepth="0"
  (onRowSelectionChange)="handleRowSelection($event)" [exportCsv]="true" exportCsvText="Export to CSV"
  [emptyGridMessage]="'Orders will appear here automatically after checkout.'" [height]="null">
  <ng-template igxToolbarCustomContent>
    <app-button [id]="'reorder'" [tooltip]="'Re-order assesments from a previous order'" [icon]="'add_shopping_cart'"
      [data]="selected" [action]="reorderAction" [validator]="reorderValidator">
    </app-button>
    <app-button [id]="'receipt'" [tooltip]="'View receipt'" [icon]="'receipt_long'" [data]="selected"
      [action]="receiptAction" [validator]="receiptValidator"></app-button>
  </ng-template>
  <ng-template igxRowSelector let-rowContext>
    <igx-checkbox [style.visibility]="rowContext.rowID.assessments.length > 0 ? 'visible' : 'hidden'"
      [checked]="rowContext.selected" [readonly]="true">
    </igx-checkbox>
  </ng-template>
  <igx-column field="number" dataType="string" [sortable]="true" header="Number"></igx-column>
  <igx-column field="title" dataType="string" [sortable]="true" header="Assessments"></igx-column>
  <igx-column field="quantity" dataType="string" [sortable]="true" header="Quantity"></igx-column>
  <igx-column field="date" dataType="string" [sortable]="true" header="Date"></igx-column>
</igx-tree-grid>