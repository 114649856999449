<button *ngIf="user" igxButton="icon" [igxToggleAction]="profileMenu" [igxToggleOutlet]="profileOutlet"
  [overlaySettings]="overlaySettings" [igxDropDownItemNavigation]="profileMenu">
  <igx-icon fontSet="material">account_circle</igx-icon>
  <span class="button-space"></span>
  <igx-icon fontSet="material">arrow_drop_down</igx-icon>
</button>

<igx-drop-down #profileMenu>
  <igx-drop-down-item (click)="signout()">Logout</igx-drop-down-item>
</igx-drop-down>

<div class="overlayOutlet" igxOverlayOutlet #profileOutlet="overlay-outlet"></div>