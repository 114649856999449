import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { FN_SEND_EMAIL } from '../../../../shared/src/constants';
import { Message } from '../../../../shared/src/models/message';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private fns: AngularFireFunctions,
  ) { }

  /**
   * Creates an invitation for a candidate to take an assessment(s).
   * @param candidateEmail to send message to 
   * @param candidateName either the first or full name used in salutation 
   * @param assessmentTitles lists all assessments awaiting
   * @param sessionIds optional sessions if updating the session with email status is desired
   */
  createInvite(candidateEmail: string, candidateName: string, assessmentTitles: string[], sessionIds?: string[]): Message {
    const message = {
      to: candidateEmail,
      from: 'noreply@talevation.com',
      subject: `Your assessment${assessmentTitles.length > 1 ? 's are' : ' is'} ready`,
      content: `Hi ${candidateName},\n\nThe following assessment${assessmentTitles.length > 1 ? 's are' : ' is'} ready for you:\n`,
    } as Message;

    if (sessionIds) {
      message.sessionIds = sessionIds;
    }

    message.content = assessmentTitles.reduce((text, title) => text.concat(`${title}\n`), message.content);
    message.content += `\nPlease visit https://app.talevation.io and sign up using this email address. Then simply click the start button next to each assessment to begin taking a test.\n\nIf you have any questions, please use the Help widget located in the lower right of the application.\n\n`

    return message;
  }

  /**
   * Sends an email.
   * @param message to send
   */
  sendEmail(message: Message) {
    return this.fns.httpsCallable(FN_SEND_EMAIL)(message);
  }
}
