import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, NoOpScrollStrategy, ISelectionEventArgs } from 'igniteui-angular';

import { Report } from '../../../../../../shared/src/models/results';
import { Session } from 'src/app/models/session';
import { ResultsService } from 'src/app/services';

@Component({
  selector: 'download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnChanges {

  @Input() session: Session;

  overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom
    }),
    scrollStrategy: new NoOpScrollStrategy()
  };

  reports: Report[];

  constructor(
    private resultsService: ResultsService,
    private storage: AngularFireStorage,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.reports = [];

    if (this.session) {
      this.resultsService.getResults(this.session).subscribe(results => {
        this.reports = results.reports;
      });
    }
  }

  async onSelection(eventArgs: ISelectionEventArgs) {
    const report: Report = eventArgs.newSelection.value;
    const url = await this.storage.storage.refFromURL(report.url).getDownloadURL();

    window.open(url, '_blank');
  }
}
