import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserService, SessionService, OrderService } from 'src/app/services';
import { User } from 'src/app/models/user';
import { IgxNavigationDrawerComponent } from 'igniteui-angular';

interface NavItem {
  path: string;
  title: string;
  icon: string;
  prev?: number;
  curr?: number;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements AfterViewInit {

  @ViewChild('drawer') drawer: IgxNavigationDrawerComponent;

  readonly NOT_STARTED: NavItem = { path: '/sessions', title: 'Not Started', icon: 'assignment' };
  readonly IN_PROGRESS: NavItem = { path: '/sessions', title: 'In Progress', icon: 'assignment_ind' };
  readonly COMPLETED: NavItem = { path: '/sessions', title: 'Completed', icon: 'assignment_turned_in' };
  readonly EXPIRED: NavItem = { path: '/sessions', title: 'Expired', icon: 'assignment_late' };

  readonly ACCOUNT: NavItem = { path: '/account', title: 'Account', icon: 'account_balance_wallet' };
  readonly ORDERS: NavItem = { path: '/orders', title: 'Orders', icon: 'shopping_cart' };

  readonly SUPPORT: NavItem = { path: '/support', title: 'Support', icon: 'support' };

  assessmentNav: NavItem[] = [];
  manageNav: NavItem[] = [];
  helpNav: NavItem[] = [];

  user: User;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private orderService: OrderService,
    private sessionService: SessionService,
    public userService: UserService
  ) {
    this.userService.user$.subscribe(user => {
      if (user !== null) {
        if (user.customerId) {
          this.assessmentNav = [this.NOT_STARTED, this.IN_PROGRESS, this.COMPLETED, this.EXPIRED];
          this.manageNav = [this.ORDERS, this.ACCOUNT];
          this.helpNav = [];
        } else {
          this.assessmentNav = [this.NOT_STARTED, this.IN_PROGRESS, this.COMPLETED];
          this.manageNav = []; // set to empty because a manager may sign out and then sign in a candidate
          this.helpNav = [];
        }

        // listen for changes to show notification badges
        this.sessionService.notStarted$.subscribe(sessions => this.adjustBadge(this.NOT_STARTED, sessions.length));
        this.sessionService.inProgress$.subscribe(sessions => this.adjustBadge(this.IN_PROGRESS, sessions.length));
        this.sessionService.completed$.subscribe(sessions => this.adjustBadge(this.COMPLETED, sessions.length));
        this.sessionService.expired$.subscribe(sessions => this.adjustBadge(this.EXPIRED, sessions.length));
        this.orderService.orders$.subscribe(sessions => this.adjustBadge(this.ORDERS, sessions.length));
      }

      // NOTE set the user (even if it's null) because logout sends null and the nav should hide
      this.user = user;
    });
  }

  ngAfterViewInit() {
    this.userService.user$.subscribe(user => {
      if (user !== null) {
        // FIXME (van) this is a UI timing hack to open the drawer only after the ngIf adds it to the page
        let delay = !this.drawer ? 1000 : 0;

        setTimeout(() => this.breakpointObserver.observe([Breakpoints.Web]).subscribe(result =>
          result.matches ? this.drawer.open() : this.drawer.close()), delay);
      }
    });
  }

  adjustBadge(item: NavItem, currentValue: number) {
    if (item.prev === undefined) {
      item.prev = currentValue;
    } else {
      item.curr = currentValue;

      // if curr drops below prev, set the two equal so we only notify incremental changes
      if (item.curr - item.prev < 0) {
        this.resetBadge(item);
      }
    }
  }

  resetBadge(item: NavItem) {
    item.prev = item.curr;
  }

  toggle() {
    this.drawer.toggle();
  }
}
