<button id="download-report" igxButton="outlined" igxRipple igxRippleCentered="true" [igxToggleAction]="downloadMenu"
  [igxToggleOutlet]="downloadOutlet" [overlaySettings]="overlaySettings" [igxDropDownItemNavigation]="downloadMenu"
  #target="tooltipTarget" [igxTooltipTarget]="tooltip" [disabled]="!reports || reports.length == 0">
  <span>report</span>
  <igx-icon fontSet="material">arrow_drop_down</igx-icon>
</button>

<div #tooltip="tooltip" igxTooltip>
  Download detailed report
</div>

<igx-drop-down #downloadMenu (onSelection)="onSelection($event)">
  <igx-drop-down-item *ngFor="let report of reports" [value]="report">
    {{ report.title }}
  </igx-drop-down-item>
</igx-drop-down>

<div class="overlayOutlet" igxOverlayOutlet #downloadOutlet="overlay-outlet"></div>