<igx-grid [data]="items" [autoGenerate]="false" [rowSelection]='"none"' [cellSelection]="'none'" [paging]="true"
  [showToolbar]="true" toolbarTitle="Account" [exportCsv]="true" exportCsvText="Export to CSV"
  [emptyGridMessage]="'Calculating account ...'" [height]="null">
  <igx-column field="title" [sortable]="true" header="Assessment">
    <ng-template igxCell let-cell="cell">
      {{cell.rowData.title}}
    </ng-template>
  </igx-column>
  <igx-column field="quantity" dataType="string" [sortable]="true" header="Available"></igx-column>
</igx-grid>
<app-assessment-overlay #overlay></app-assessment-overlay>