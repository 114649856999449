import { Component, Input } from '@angular/core';
import { Session } from 'src/app/models/session';
import { SessionService, OrderService } from 'src/app/services';
import { IgxDialogComponent, IgxGridComponent } from 'igniteui-angular';

@Component({
  selector: 'app-delete-session',
  templateUrl: './delete-session.component.html',
  styleUrls: ['./delete-session.component.scss']
})
export class DeleteSessionComponent {

  @Input() grid: IgxGridComponent;
  @Input() sessions: Session[] = [];

  closeDialogLabel = 'Close';
  actionDialogLabel = 'Cancel';
  progressDialogLabel = 'Canceling ...';

  leftButtonLabel = this.closeDialogLabel;
  rightButtonLabel = this.actionDialogLabel;

  constructor(private sessionService: SessionService) { }

  isDeleteable() {
    if (!this.sessions || this.sessions.length === 0) {
      return false;
    } else {
      return this.sessions.every(session => session.status !== 'Completed' && session.hasUserAsOwner);
    }
  }

  cancel(dialog: IgxDialogComponent) {
    // check if the dialog is already processing
    if (this.rightButtonLabel !== this.progressDialogLabel) {
      if (this.grid) {
        this.grid.selectionService.clearAllSelectedRows();
      }

      // signal progress to user
      this.rightButtonLabel = this.progressDialogLabel;

      // TODO (van) investigate forkJoin to concurrently delete and then close when done

      // delete sessions
      this.sessionService.deleteSession(this.sessions).subscribe(() => {
        this.sessions = [];
        dialog.close();
        this.reset();
      });
    }
  }

  reset() {
    this.rightButtonLabel = this.actionDialogLabel;
  }

}
