<igx-grid #grid [data]="sessions" [autoGenerate]="false" [paging]="true" [perPage]="15" [cellSelection]="'none'"
  [showToolbar]="true" toolbarTitle="{{status}} Assessments" (onRowSelectionChange)="handleRowSelection($event)"
  [emptyGridMessage]="isCustomer ? 'Assessments will appear here automatically when they are started by candidates.' : 'You have no in-progress assessments.'"
  [exportCsv]="true" exportCsvText="Export to CSV" [allowFiltering]="filterable" [height]="height">
  <ng-template igxToolbarCustomContent let-grid="grid">
    <button igxButton="outlined" igxRipple (click)="toggleFilter()">
      <igx-icon fontSet="material">filter_list</igx-icon>
    </button>
  </ng-template>
  <igx-column *ngIf="hasUserSession" header=" " [filterable]="false" width="160">
    <ng-template igxCell let-cell="cell">
      <app-session-button [session]="cell.rowData"></app-session-button>
    </ng-template>
  </igx-column>
  <igx-column *ngIf="isCustomer" field="candidateName" [sortable]="true" header="Candidate"></igx-column>
  <igx-column field="assessmentTitle" [sortable]="true" header="Assessment">
    <ng-template igxCell let-cell="cell">
      {{cell.rowData.assessmentTitle}}
    </ng-template>
  </igx-column>
  <igx-column field="startDate" [sortable]="true" header="Started On"></igx-column>
</igx-grid>
<app-assessment-overlay #overlay></app-assessment-overlay>