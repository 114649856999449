export const EmailRegEx = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}';

/**
 * Verifies that an email address appears valid to prevent copy paste related errors.
 * @param email address to be verified
 */
export function isEmailValid(email: string): boolean {
  const regex = new RegExp(EmailRegEx);

  const atPos = email.lastIndexOf('@');
  const dotPos = email.lastIndexOf('.');
  const tld = email.substring(dotPos + 1);

  const letter = new RegExp('[a-z0-9A-Z]{1}');

  return regex.test(email) && tld.length > 1 && letter.test(email.charAt(0)) &&
    letter.test(email.charAt(email.length - 1)) && atPos > -1 && dotPos > atPos;
}