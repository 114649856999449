import { Session as StoredSession, SessionStatus } from '../../../../shared/src/models/session';
import { MessageStatus } from '../../../../shared/src/models/message';
import { User } from './user';

export class Session {

  assessmentId: string;
  assessmentTitle: string;
  candidateName: string;
  candidateEmail: string;
  createDate: string;
  endDate: string;
  id: string;
  provisionDate: string;
  startDate: string;
  status: SessionStatus;
  url: string;
  score?: string;
  notifyByEmail?: boolean;
  messageStatus?: MessageStatus;
  seatTime: string;

  belongsToUser = false;
  hasUserAsOwner = false;
  hasUserAsManager = false;
  hasUserAsViewer = false;

  constructor(public stored: StoredSession, user?: User) {
    this.assessmentId = stored.assessment.id;
    this.assessmentTitle = stored.assessment.title;
    this.candidateName = `${stored.candidate.firstName} ${stored.candidate.lastName}`;
    this.candidateEmail = stored.candidate.email;
    this.createDate = stored.createTimestamp ? new Date(stored.createTimestamp).toDateString() : '';
    this.id = stored.id;
    this.endDate = stored.endTimestamp ? new Date(stored.endTimestamp).toDateString() : '';
    this.provisionDate = stored.provisionTimestamp ? new Date(stored.provisionTimestamp).toDateString() : '';
    this.startDate = stored.startTimestamp ? new Date(stored.startTimestamp).toDateString() : this.provisionDate;
    this.status = stored.status;
    this.url = stored.url ? stored.url : '';
    this.messageStatus = stored.messageStatus;
    this.seatTime = stored.seatTime || '-';

    // NOTE legacy check for assessments that had no email address for candidate
    if (stored.candidate.email === undefined) {
      this.candidateEmail = '';
    }

    if (user) {
      // NOTE that emails for candidate is not hashed and when provisioned is then hashed and ID is set
      this.belongsToUser = this.candidateEmail === user.email.toLowerCase() || stored.candidate.id === user.id;
      this.hasUserAsOwner = stored.owner === user.id;
      this.hasUserAsManager = this.emailMatches(stored.managers, user);
      this.hasUserAsViewer = this.emailMatches(stored.viewers, user);
    }

    this.score = (stored.score === '50 %tile' && (
      this.assessmentTitle.startsWith('Leadership Preference') ||
      this.assessmentTitle.startsWith('Occupational Personality Inventory') ||
      this.assessmentTitle.startsWith('Motivation Questionnaire') ||
      this.assessmentTitle.startsWith('Learning Styles Questionnaire')
    )) ? 'N/A' : stored.score
  }

  private emailMatches(email: string | string[], user: User) {
    return typeof email === 'string' ? email.toUpperCase() === user.emailHash.toUpperCase() :
      email.find(e => e.toUpperCase() === user.emailHash.toUpperCase()) !== undefined;
  }
}